@import "../../mixins/bs_breakpoints.scss";
@import "../../mixins/adjustable-font-size";

.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &__video {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;

    &-play {
      display: block;
      left: 50%;
      top: 50%;
      position: absolute;
      width: 50px;
      height: auto;
      cursor: pointer;
      z-index: 70;
      transform: translate(-45%, -50%);
      transition: transform .3s ease;

      &:hover {
        transform: translate(-45%, -50%) scale(1.1);
      }
    }

  }

  &__content {
    z-index: 2;
    position: relative;
    width: 60%;
    height: 62vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 16.1vw;
    height: auto;
  }

  &__title {
    font-weight: 200;
    @include interpolate(font-size, 768px, 1920px, 28px, 36px);
    text-align: center;
  }

  &__button {
    width: auto;
    line-height: 26px;
    padding-left: 40px;
    padding-right: 40px;
    @include interpolate(font-size, 768px, 1920px, 16px, 18px);
    box-shadow: none;
  }
}

.video_interactive {
  transition: opacity .7s ease, max-width .7s ease;
  overflow: hidden;
  position: fixed;
  max-width: calc(100vw - 210px);
  max-height: 100vh;
  min-height: 100vh;
  left: 210px;
  width: calc(100vw - 210px);
  background-color: #000;


  @include media-breakpoint-down(sm) {
    max-width: 0;
    opacity: 0;
    z-index: 1;
    width: 100vw;
    left: 0;
  }

  &.active {
    max-width: 100%;
    max-height: 100vh;
    opacity: 1;
    min-height: 100vh;
    min-width: 100%;
    display: block;


  }
}

.department__home {
  cursor: pointer;
  z-index: 3;
  @include interpolate(width, 768px, 1920px, 110px, 145px);
  @include interpolate(height, 768px, 1920px, 110px, 145px);
  box-shadow: 0 0 26px 0 rgba(84, 42, 5, 0.53);
  background: #f39f33;
  border-radius: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease, transform .3s ease;
  display: flex;
  opacity: 0;
  max-height: 0;
  max-width: 0;
  color: #fff;

  @include media-breakpoint-down(sm) {
    @include interpolate(height, 320px, 767px, 95px, 120px);
    @include interpolate(width, 320px, 767px, 95px, 120px);
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    transform: scale(1.2);

  }

  .department__svg {
    @include interpolate(height, 320px, 767px, 25px, 50px);
    @include interpolate(width, 320px, 767px, 25px, 50px);
    max-width: 0;
    max-height: 0;
  }

  .department__title {
    display: none;
  }

  .department-icon {
    display: none;
  }

  &.shown {
    opacity: 1;
    max-height: 100%;
    max-width: 100%;
    transition: background-color 1.7s ease, transform .3s ease, opacity 1.7s ease;

    .department__title {
      display: block;
    }

    .department__svg {
      max-width: 100%;
      max-height: 100%;
    }

    > .department-icon {
      display: block;
    }
  }
}

.department__wrapper {
  z-index: 5;
  pointer-events: auto;

  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

.department__wrapper_pm {
  position: absolute;
  top: 30px;
  right: 15%;

  @include media-breakpoint-down(sm) {
    top: 10px;
    right: 10px;
  }
}

.department__wrapper_backend {
  position: absolute;
  top: 30px;
  left: 3%;

  @include media-breakpoint-down(sm) {

  }
}

.department__wrapper_design {
  z-index: 4;
  position: absolute;
  top: 30px;
  left: 10%;

  @include media-breakpoint-down(sm) {
    bottom: 10px;
    left: 10px;
  }
}

.department__wrapper_frontend {
  position: absolute;
  bottom: 50px;
  right: 10%;

  @include media-breakpoint-down(sm) {
    bottom: 10px;
    right: 10px;
  }
}

.department__wrapper_qa {
  position: absolute;
  bottom: 15%;
  left: 15%;

  @include media-breakpoint-down(sm) {
    top: 10px;
    bottom: auto;
    left: auto;
    right: 10px;
  }
}

.department__wrapper_marketing {
  z-index: 3;
  position: absolute;
  top: 15%;
  left: 15%;

  @include media-breakpoint-down(sm) {
    top: 10px;
    left: 10px;
  }
}

#mainVideo {
  width: 100%;
  position: absolute;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.department-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  position: absolute;
  width: 30vw;
  text-align: center;
  box-shadow: 0 0 10px rgba(84, 42, 5, 0.33);
  padding: 30px 20px;
  background-image: linear-gradient(to left, #d96600 0%, #f39f33 100%);
  transition: opacity .7s ease, max-height .7s ease;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: auto;

  @include media-breakpoint-down(sm) {
    width: 80vw;
    position: fixed;
    left: 50%;
    top: 50%;
    max-height: 100vh;
    height: auto;
    margin-top: 0 !important;
    margin-left: 0 !important;
    transform: translate(-50%, -50%) !important;
  }

  &.active {
    max-height: 1000px;
    opacity: 1;
    visibility: visible;

  }

  &__title {
    font-weight: 200;
    font-size: 26px;
    margin: 0;
    padding: 0 15px;
  }

  &__text {
    font-weight: 200;
    font-size: 16px;
    margin-top: 20px;
  }


  &__button {
    width: auto;
    height: auto;
    padding: 8px 22px;
    margin-top: 15px;
  }
}

.mob-home {
  display: none;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  padding-left: 15%;
  padding-right: 15%;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }

  &__logo {
    width: 50%;
  }

  &__title {
    font-weight: 200;
    width: 100%;
  }

  &__play {
    width: 20vw;
    height: 20vw;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &_img {
      width: 100%;
      height: 100%;
      margin-right: -5%;
    }
  }

  &__buttons-wrapper {
    width: 100%;
  }

  &__button {
    height: auto;
    font-size: 3vw;
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
    width: 100%;
  }

  &__contact-buttons {
    display: flex;
    margin-left: -1.5vw;
    margin-right: -1.5vw;
  }

  &__contact-button {
    margin-top: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: rgba(#fff, .3);
    border-radius: 5px;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    color: #fff;

    svg {
      font-size: 5vw;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 35%;
    }
  }

}
